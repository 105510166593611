<template>
  <div>
    <template v-for="item in data">
      <b-row
        v-if="except.indexOf(item.key) === -1"
        :key="item.key"
      >
        <b-col cols="12">
          <slot
            :name="item.key"
            :item="item"
          >
            <b-row>
              <b-col
                class="pt-1 pb-1"
                :cols="labelCols"
              >
                {{ item.label ? item.label : item.key.replace('_', ' ') | titleCase }}
              </b-col>
              <b-col
                class="pt-1 pb-1"
                :cols="valueCols"
              >{{ item.value }}</b-col>
            </b-row>
          </slot>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    except: {
      type: Array,
      default() {
        return []
      },
    },
    labelCols: {
      type: Number,
      default: 4,
    },
    valueCols: {
      type: Number,
      default: 8,
    },
  },
}
</script>
