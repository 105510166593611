<template>
  <div>
    <inner-error
      v-if="showError"
      message="Операция не найдена"
      :backUrl="{ name: 'operation-list' }"
    />
    <b-card
      v-else
    >
      <data-view
        :data="operation"
      >
      </data-view>
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import InnerError from '../components/InnerError.vue'
import DataView from '../components/DataView.vue'

export default {
  components: { InnerError, DataView, BCard },
  data () {
    return {
      showError: false,
      operation: [],
      fieldsLabels: {
        driver_id: 'Driver Id'
      }
    }
  },
  mounted () {
    this.$axios.get('operations/' + this.$route.params.id).then((response) => {
      for (const [field, value] of Object.entries(response.data.data)) {
        this.operation.push({
          key: field,
          value,
          label: this.fieldsLabels[field]
        })
      }
    }).catch((error) => {
      if (error.response.status === 404) {
        this.showError = true
      }
    })
  }
}
</script>
