<template>
  <div class="d-flex flex-column pt-5">
    <div class="p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ message }}
        </h2>
        <p class="mb-2">
          {{ description }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="backUrl"
        >
          Вернуться
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { BLink, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
export default {
  components: {
    BLink,
    BButton,
    VuexyLogo
  },
  props: {
    message: {
      type: String,
      default: "Не найдено!"
    },
    description: {
      type: String,
      default: ''
    },
    backUrl: {
      default () {
        return { path: '/' }
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
